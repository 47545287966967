<template>
  <div class="d-flex-column main align-center mw-700">
    <el-card class="align-text-center">
      <h3>Registration Complete</h3>
      <p>We’re glad to have you on-board for the upcoming season.</p>
      <p>A confirmation email with details has been sent to your email account.</p>
      <p>For further information please contact your local club or association.</p>
    </el-card>
    <el-button type="primary" class="mt-1 button w-90" @click="$router.push({ name: 'profile' })">
      Go to profile
    </el-button>
  </div>
</template>

<script>
export default {
  name: "PaymentComplete",
  created() {
    // remove for now. being revisited in 2023
    // gtag('event', 'conversion', {
    //   allow_custom_scripts: true,
    //   send_to: 'DC-4375528/legsta/legsta5+standard',
    // });
  },
};
</script>
